import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import { logOut } from "../../store/Auth";
import DialogAction from "../DialogAction";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";

function Header() {
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.Auth.auth);
  const user = useSelector((state) => state.Auth.user);
  const [mobileOpen, setMobileOpen] = useState(false);

  const [logout, setLogout] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menu = [
    {
      text: "Карта деталей",
      url: "/",
    },
    {
      text: "Сборки",
      url: "/vars",
    },
    {
      text: "Кратность",
      url: "/rate",
    },
    {
      text: "Модели по группам",
      url: "/model-group",
    },
    {
      text: "Карта моделей",
      url: "/map-models",
    },
    {
      text: "1c",
      url: "/sync",
    },
    {
      text: "Поиск",
      url: "/search",
    },
  ];

  const link = (url) => {
    navigate(url);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const _logOut = () => {
    handleClose();
    dispatch(logOut());
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      <AppBar position="static" color="inherit">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <ShoppingBagOutlinedIcon sx={{ color: "#5955b3" }} />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "Roboto",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "#5955b3",
                textDecoration: "none",
                ml: 2,
              }}
            >
              SHOP ADMIN
            </Typography>
            {auth && (
              <>
                <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleDrawerToggle}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Box component="nav">
                    <Drawer
                      container={container}
                      variant="temporary"
                      open={mobileOpen}
                      onClose={handleDrawerToggle}
                      ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                      }}
                      sx={{
                        display: { xs: "flex", lg: "none" },
                        "& .MuiDrawer-paper": {
                          boxSizing: "border-box",
                          width: 240,
                        },
                      }}
                    >
                      <List onClick={handleDrawerToggle}>
                        {menu.map((page) => (
                          <ListItem key={page.text} disablePadding>
                            <ListItemButton onClick={() => link(page.url)}>
                              <ListItemText primary={page.text} />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Drawer>
                  </Box>
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: "none", lg: "flex" } }}>
                  {menu.map((page) => (
                    <Button
                      key={page.text}
                      onClick={() => link(page.url)}
                      sx={{ my: 2, color: "inherit", display: "block" }}
                    >
                      {page.text}
                    </Button>
                  ))}
                </Box>
                <div>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span>{user.name}</span>
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                    >
                      <AccountCircle sx={{ color: "#5955b3" }} />
                    </IconButton>
                  </Typography>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => setLogout(true)}>Выход</MenuItem>
                  </Menu>
                </div>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <DialogAction
        title="Выход"
        text="Вы уверенны что хотите выйти?"
        agree="Выйти"
        open={logout}
        setOpen={setLogout}
        action={_logOut}
      />
    </>
  );
}

export default Header;
