import Wrapper from "./components/Wrapper";
import { Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import Variants from "./screens/Variants";
import Variant from "./screens/Variants/Variant";
import Auth from "./screens/Auth";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUser, setAuth } from "./store/Auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Rate from "./screens/Rate";
import ModelGroup from "./screens/ModelGroup";
import Sync from "./screens/Sync";
import ModelMap from "./screens/ModelMap";
import Search from "./screens/Search";

function App() {
  const auth = useSelector((state) => state.Auth.auth);
  const token = useSelector((state) => state.Auth.token);

  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(getUser());
    } else {
      dispatch(setAuth(false));
    }
  }, [auth]);

  if (auth === null) return <></>;

  return (
    <Wrapper>
      {auth ? (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/vars" element={<Variants />} />
          <Route path="/vars/:id" element={<Variant />} />
          <Route path="/rate" element={<Rate />} />
          <Route path="/model-group" element={<ModelGroup />} />
          <Route path="/map-models" element={<ModelMap />} />
          <Route path="/sync" element={<Sync />} />
          <Route path="/search" element={<Search />} />
        </Routes>
      ) : (
        <Auth />
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={1000}
        hideProgressBar
      />
    </Wrapper>
  );
}

export default App;
